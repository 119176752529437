import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchCertificates } from '../rtk/slices/cretificates-slice';
import { useTranslation } from 'react-i18next';

function Certificate () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { code } = useParams();
    const { t, i18n } = useTranslation();


    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const dispatch = useDispatch();
    const certificates = useSelector((state) => state.certificates.certificates);
    const color = useSelector((state) => state.color.color);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchCertificates());

    }, [])

    return (
        <section className='projects service' id='projects' style={{ userSelect: 'none' }}>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الشهادات" : "Certificates"}</span>
                    <p style={{ textTransform: "capitalize" }}>{code}</p>
                </h2>

                {certificates.map((service) => {
                    console.log(service.code);
                    return (
                        service['code'] === code ?
                            <div className="card-service">
                                <div className="img" style={{ maxWidth: "60rem" }}>
                                    <img style={{ maxWidth: "30rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img}`} alt="" />
                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/certificates`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Certificate
