import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function Addresses () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])
    return (
        <section className='contact' id='addresses'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "عناويننا" : "Our Addresses"}</span>
                    <p>{i18n.language == "ar" ? "عناويننا" : "Our Addresses"}</p>
                </h2>

                <div className="maps">
                    <div class="map">
                        <div className="des" style={{ padding: "1rem" }}>
                            <div style={{ color: "gray", fontSize: "1.4rem", paddingBottom: "1rem" }}>{i18n.language == "ar" ? "مواعيد العمل: السبت - الخميس: 9:00 ص إلى 9:00 م" : "Opening hours: Saturday-Thursday: 9: 00 AM to 9: 00 pm"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "العنوان" : "Address Info"}</label>
                            <div className="address">{i18n.language == "ar" ? "الفرع الاول : ٣٣ ميدان سراي القبة - أمام سنترال سراي القبة - بجوار محطة مترو سراي القبة" : "Branch 1: 33 Saray El Kobba Square - In front of Saray El Kobba Central - Next to Saray El Kobba Metro Station"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "البريد الإالكتروني" : "Email"}</label>
                            <div className="email"><a href="mailto:magidramly@gmail.com?subject=Website">magidramly@gmail.com</a></div>
                            <label htmlFor="number" class="labelContact">{i18n.language == "ar" ? "رقم الهاتف" : "Phone Number"}</label>
                            <div htmlFor="number"><a href="tel:+201004844850">01004844850</a></div>
                            <div htmlFor="number"><a href="tel:+201226139946">01226139946</a></div>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.8906087750574!2d31.307218499999998!3d30.0973191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583fd55f9e6853%3A0x6008ed3c24b5c6f3!2z2YXYsdmD2LIg2YHYsditINmE2YTYudmE2KfYrCDYp9mE2LfYqNmK2LnZiiDYp9mE2YXZg9ir2YEg2YTZhNij2LfZgdin2YQ!5e0!3m2!1sar!2seg!4v1720104765406!5m2!1sar!2seg"></iframe>
                    </div>

                    <div class="map">
                        <div className="des" style={{ padding: "1rem" }}>
                            <div style={{ color: "gray", fontSize: "1.4rem", paddingBottom: "1rem" }}>{i18n.language == "ar" ? "مواعيد العمل: السبت - الخميس: 9:00 ص إلى 9:00 م" : "Opening hours: Saturday-Thursday: 9: 00 AM to 9: 00 pm"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "العنوان" : "Address Info"}</label>
                            <div className="address">{i18n.language == "ar" ? "الفرع الثاني : ١١٣ شارع بور سعيد - أعلى عصير فرغلي - ميدان السواح" : "Branch 2: 113 Port Said Street - Above Farghaly Juice - El Sawah Square"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "البريد الإالكتروني" : "Email"}</label>
                            <div className="email"><a href="mailto:magidramly@gmail.com?subject=Website">magidramly@gmail.com</a></div>
                            <label htmlFor="number" class="labelContact">{i18n.language == "ar" ? "رقم الهاتف" : "Phone Number"}</label>
                            <div htmlFor="number"><a href="tel:+201004844850">01004844850</a></div>
                            <div htmlFor="number"><a href="tel:+201226139946">01226139946</a></div>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3451.5356584848682!2d31.284784384883324!3d30.107482181859442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDA2JzI2LjkiTiAzMcKwMTYnNTcuMyJF!5e0!3m2!1sar!2seg!4v1720106971779!5m2!1sar!2seg"></iframe>
                    </div>

                    <div class="map">
                        <div className="des" style={{ padding: "1rem" }}>
                            <div style={{ color: "gray", fontSize: "1.4rem", paddingBottom: "1rem" }}>{i18n.language == "ar" ? "مواعيد العمل: السبت - الخميس: 9:00 ص إلى 9:00 م" : "Opening hours: Saturday-Thursday: 9: 00 AM to 9: 00 pm"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "العنوان" : "Address Info"}</label>
                            <div className="address">{i18n.language == "ar" ? "الفرع الثالث : ٥٠ شارع 10 - بجوارب بنزينة التعاون - اول الشارع الجديد - شبرا الخيمة" : "Branch 3: 50 Street 10 - Next to Cooperation Gas Station - At the beginning of the new street - Shubra El Kheima"}</div>
                            <label className='labelContact' htmlFor="address">{i18n.language == "ar" ? "البريد الإالكتروني" : "Email"}</label>
                            <div className="email"><a href="mailto:magidramly@gmail.com?subject=Website">magidramly@gmail.com</a></div>
                            <label htmlFor="number" class="labelContact">{i18n.language == "ar" ? "رقم الهاتف" : "Phone Number"}</label>
                            <div htmlFor="number"><a href="tel:+201004844850">01004844850</a></div>
                            <div htmlFor="number"><a href="tel:+201226139946">01226139946</a></div>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3450.6780806452193!2d31.28722328488277!3d30.132023881850436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDA3JzU1LjMiTiAzMcKwMTcnMDYuMSJF!5e0!3m2!1sar!2seg!4v1720104699134!5m2!1sar!2seg"></iframe>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Addresses
