import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchColor } from '../rtk/slices/color-slice'
import { useTranslation } from 'react-i18next'
import { fetchBookings } from '../rtk/slices/bookings-slice'

function Machines_Page () {
    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const machines = useSelector((state) => state.machines.machines);

    const { t, i18n } = useTranslation();

    const machines_en = machines.map(blo_en => {
        if (i18n.language == "en") {
            return ({
                "id": blo_en.id,
                "img": blo_en.img,
                "name": blo_en.name_en,
                "used": blo_en.used_en
            })
        }
        return blo_en;
    })


    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchBookings())

    }, [])

    return (
        <section className=' memories projects blogs black machines' id='projects'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=201226139946" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الألات" : "Machines"}</span>
                    <p>{i18n.language == "ar" ? "الألات" : "Machines"}</p>
                </h2>
                <div className="cards">
                    {machines_en.map((card) => {
                        return (
                            <Link to={`/machines/machine/${card.id}`} data-aos="zoom-out-up" className="card" key={card.id}>
                                <div style={{ textAlign: "center" }}>
                                    <img style={{ width: "100%", maxWidth: "19rem", maxHeight: "13rem", borderRadius: ".5rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img}`} alt="" />
                                </div>
                                <h4 style={{ textAlign: 'center' }}>{card.name}</h4>
                                <p>{card.used.substring(0, 100)}..</p>
                            </Link>
                        )
                    })}
                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Machines_Page
