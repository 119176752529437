import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchCertificates = createAsyncThunk('certificatesSlice/fetchCertificates', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?certificates`)
    const data = await res.json()
    return data;
})

const certificatesSlice = createSlice({
    initialState: {
        certificates: [],
        loading: false,
        error: null,
    },
    name: 'certificatesSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchCertificates.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCertificates.fulfilled, (state, action) => {
                state.loading = false;
                state.certificates = action.payload;
            })
            .addCase(fetchCertificates.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = certificatesSlice.actions;

export default certificatesSlice.reducer;