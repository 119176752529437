import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { useTranslation } from 'react-i18next';
import { fetchBookings } from '../rtk/slices/bookings-slice';
import { fetchTrainings } from '../rtk/slices/trainings-slice';

function Training () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const dispatch = useDispatch();
    const trainings = useSelector((state) => state.trainings.trainings);
    const color = useSelector((state) => state.color.color);

    const trainings_en = trainings.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img": ser_en.img,
                "title": ser_en.title_en,
                "time": ser_en.time_en,
                "location": ser_en.location_en,
                "number": ser_en.number,
            })
        }
        return ser_en;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchTrainings());

    }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=201226139946" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "التوظيف والتدريب" : "Employment and Training"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "التوظيف والتدريب" : "Employment and Training"}</p>
                </h2>


                {trainings_en.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service">
                                <div className="content" style={{
                                    padding: '1rem', boxShadow: '0px 0px 3px 3px var(--main-color-opacity-50)'
                                }}>
                                    <div className="box">
                                        <h3>{service.title}</h3>
                                        <br />
                                        <p><p style={{ background: 'var(--main-color-opacity-35)' }}>{i18n.language == "ar" ? "المتطلبات: " : "Requirements: "} </p>
                                            {service.requirements.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div style={{ textAlign: "center", marginTop: '1rem' }} class="btns"><button class="button type1"><span class="btn-txt"><a target='_blank' href={`https://api.whatsapp.com/send?phone=2${service.number}&&text=Farah center Website: ${service.title}`}>{i18n.language == "ar" ? "تواصل معنا" : "Contact us"}</a></span></button></div>
                                </div>
                            </div>
                            : null
                    )
                })}

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section >
    )
}

export default Training
