import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// import required modules
import { Autoplay } from 'swiper/modules';

// import required modules
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartners } from '../rtk/slices/partners-slice';
import { fetchAbout } from '../rtk/slices/about-slice';
import { useTranslation } from 'react-i18next';
// images
import { fetchCenterCertificates } from '../rtk/slices/center_certificates-slice';

function Center_Certificates () {
    const { t, i18n } = useTranslation();

    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 5 : 2;
    const dispatch = useDispatch();
    const certificates = useSelector((state) => state.centerCertificates.centerCertificates);

    useEffect(() => {
        // fetch about
        dispatch(fetchCenterCertificates());
    }, [])
    return (
        <section className='about' id='about' style={{ background: 'var(--bg-light-color)' }}>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>{i18n.language == "ar" ? "شهادات المركز" : "Center Certificates"}</span>
                    <p>{i18n.language == "ar" ? "شهادات المركز" : "Center Certificates"}</p>
                </h2>

                <div className="about-content">
                    <div className="box">
                        <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                            <p className='priceP cre' style={{ boxShadow: i18n.language == 'en' ? '-16px 0px 1px 0 var(--main-color-opacity-20)' : '16px 0px 1px 0 var(--main-color-opacity-20)' }}>
                                <span className='priceSpan'>{i18n.language == 'ar' ? 'يتميز مركز فرح للعلاج الطبيعي والتخاطب بحصوله على العديد من الشهادات والجوائز التي تعكس التزامه بالجودة والتميز في مجال الرعاية الصحية. نحن فخورون بأن نكون معتمدين من قبل الهيئات الصحية الرائدة، كما تم تكريمنا بجوائز متعددة تقديرًا لجهودنا في تقديم أفضل الخدمات العلاجية للمرضى. تفانينا في تحسين حياة عملائنا ينعكس في شهادات التقدير التي تلقيناها من العديد من المؤسسات المرموقة، مما يعزز مكانتنا كأحد أفضل مراكز العلاج الطبيعي والتخاطب في المنطقة.' : 'Farah physiotherapy and Speech Therapy Center is distinguished by having received many certificates and awards that reflect its commitment to quality and excellence in the field of healthcare. We are proud to be accredited by leading health bodies, and we have been honored with multiple awards in recognition of our efforts in providing the best therapeutic services to patients. Our dedication to improving the lives of our clients is reflected in the certificates of appreciation we have received from many prestigious institutions, which strengthens our position as one of the best physiotherapy and Speech Therapy Centers in the region.'}</span><br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="swiper_container">
                    <Swiper
                        spaceBetween={20}
                        initialSlide={2}
                        slidesPerView={slidesPerView}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={false}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        {certificates.map((creti) => {
                            return (
                                <SwiperSlide key={creti.id} >
                                    <a style={{ width: '100%', height: '100%', margin: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex' }} target='_blank' href={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${creti.img}`} className="card-swiper">
                                        <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${creti.img}`} alt="" />
                                    </a>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Center_Certificates
