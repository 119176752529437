import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchMachines = createAsyncThunk('machinesSlice/fetchMachines', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?machines`)
    const data = await res.json()
    return data;
})

const machinesSlice = createSlice({
    initialState: {
        machines: [],
        loading: false,
        error: null,
    },
    name: 'machinesSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchMachines.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchMachines.fulfilled, (state, action) => {
                state.loading = false;
                state.machines = action.payload;
            })
            .addCase(fetchMachines.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = machinesSlice.actions;

export default machinesSlice.reducer;