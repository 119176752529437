import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchTrainings = createAsyncThunk('trainingsSlice/fetchTrainings', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?training`)
    const data = await res.json()
    return data;
})

const trainingsSlice = createSlice({
    initialState: {
        trainings: [],
        loading: false,
        error: null,
    },
    name: 'trainingsSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchTrainings.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTrainings.fulfilled, (state, action) => {
                state.loading = false;
                state.trainings = action.payload;
            })
            .addCase(fetchTrainings.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = trainingsSlice.actions;

export default trainingsSlice.reducer;