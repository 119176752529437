import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { useTranslation } from 'react-i18next';
import { fetchBookings } from '../rtk/slices/bookings-slice';

function Booking () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const dispatch = useDispatch();
    const bookings = useSelector((state) => state.bookings.bookings);
    const color = useSelector((state) => state.color.color);

    const bookings_en = bookings.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img": ser_en.img,
                "title": ser_en.title_en,
                "time": ser_en.time_en,
                "location": ser_en.location_en,
                "number": ser_en.number,
            })
        }
        return ser_en;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchBookings());

    }, [])

    return (
        <section className='projects service' id='projects'>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "حجز" : "booking"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "حجز" : "booking"}</p>
                </h2>


                {bookings_en.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service">
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img}`} alt="" />
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3>{service.title}</h3>
                                        <br />
                                        <p><p style={{ background: 'var(--main-color-opacity-35)' }}>{i18n.language == "ar" ? "مواعيد: " : "The Time: "} </p>
                                            {service.time.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <p> <p style={{ background: 'var(--main-color-opacity-35)' }}> {i18n.language == "ar" ? "العنوان: " : "The Location: "} </p>
                                        {service.location.split(',').map((what) => {
                                            return (
                                                <>
                                                    <span>{what}</span><br />
                                                </>
                                            )
                                        })}
                                    </p>
                                    <div style={{ textAlign: "center", marginTop: '1rem', marginBottom: '1rem' }} class="btns"><button style={{ width: '170px' }} class="button type1"><span class="btn-txt"><a target='_blank' href={`https://api.whatsapp.com/send?phone=2${service.number}&&text=Farah center Website: ${service.title}`}>{i18n.language == "ar" ? "إحجز علي الواتساب" : "Book on WhatsApp"}</a></span></button></div>
                                </div>
                            </div>
                            : null
                    )
                })}
                <br />
                <div style={{ textAlign: "center", marginTop: '1rem', marginBottom: '1rem' }} class="btns">{i18n.language == "ar" ? "أو من خلال هذا النموذج" : "Or Through This Form"}</div>

                <div className="booking_form contact contact-content">
                    <form action={`${process.env.REACT_APP_PROJECTS_API}components/booking.php`} method='post' >
                        <div className="input-box">
                            <div className="input-field" style={{ width: "100%" }}>
                                <div className="icon"><i class="fa fa-user"></i></div>
                                <input className='myInput' name='name' type="text" placeholder={i18n.language == "ar" ? "الاسم بالكامل" : "Name"} required />
                            </div>
                        </div>

                        <div className="input-box">
                            <div className="input-field" style={{ width: "100%" }}>
                                <div className="icon email"><i class="fa fa-edit"></i></div>
                                <input className='myInput' name='age' type="text" placeholder={i18n.language == "ar" ? "السن" : "Age"} required />
                            </div>
                        </div>

                        <div className="input-box" >
                            <div className="input-field" style={{ width: "100%" }}>
                                <div className="icon email"><i class="fa fa-map"></i></div>
                                <input className='myInput' name='location' type="text" placeholder={i18n.language == "ar" ? "العنوان" : "The Location"} required />
                            </div>
                        </div>

                        <div className="input-box">
                            <div className="input-field" style={{ width: "100%" }}>
                                <div className="icon"><i class="fa fa-phone"></i></div>
                                <input className='myInput' name='phone' type="phone" placeholder={i18n.language == "ar" ? "رقم الهاتف" : "The Phone"} required />
                            </div>
                        </div>

                        <div className="textarea-field">
                            <div className="icon message"><i class="fa fa-comment"></i></div>
                            <textarea className='myInput' name="reason" cols={30} rows={10} placeholder={i18n.language == "ar" ? "ما هو سبب الزيارة؟" : "What is the reason for the visit"} required></textarea>
                        </div>

                        <div className="btn-box btns">
                            <button type='submit' name='send' class="button type1">
                                <span class="btn-txt"><a>{i18n.language == "ar" ? "إحجز الأن" : "Book now"}</a></span>
                            </button>
                        </div>
                    </form>
                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section >
    )
}

export default Booking
