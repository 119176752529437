import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Memories from './Memories'
import Footer from './Footer'
import Loader from './Loader'
import Contact from './Contact'
import Blogs from './Blogs'
import Services from './Services'
import Center_Certificates from './Center_Certificates'
import Our_Team from './Our_Team'
import Certificates from './Cretificates'
import Bookings from './Bookings'
import Machines from './Machines'
import Trainings from './Traninigs'
import Accordion from './FAQ'
import FAQ from './FAQ'
import Addresses from './Addresses'

function All () {
    return (
        <>
            <Loader />
            <Navbar />
            <Home />
            <About />
            <Center_Certificates />
            <Services />
            <Our_Team />
            <Certificates />
            <Bookings />
            <Machines />
            <Trainings />
            <Blogs />
            <Memories />
            <FAQ />
            <Addresses />
            <Contact />
            <Footer />
        </>
    )
}

export default All
