import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchColor } from '../rtk/slices/color-slice'
import { fetchCertificates } from '../rtk/slices/cretificates-slice';
import { useTranslation } from 'react-i18next';

function Certificates_Page () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { t, i18n } = useTranslation();

    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const certificates = useSelector((state) => state.certificates.certificates);
    const [changeSearch, setChangeSearch] = useState('');
    const [servicesSearch, setServicesSearch] = useState('');

    const filterService = (e) => {
        console.log(changeSearch);

        e.preventDefault()
        const updateData = certificates.filter((service) => service.code == changeSearch);
        setServicesSearch(updateData)
    }

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchCertificates())

    }, [])

    return (
        <section className='certificates' id='projects' style={{ userSelect: 'none' }}>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الشهادات" : "Certificates"}</span>
                    <p>{i18n.language == "ar" ? "الشهادات" : "Certificates"}</p>
                </h2>

                <form style={{ marginTop: "3rem" }}>
                    <div className="input-box">
                        <div className="input-field">
                            <input className='myInput' name='name' type="text" onChange={(e) => setChangeSearch(e.target.value)} placeholder={i18n.language == "ar" ? 'اكتب الكود ..' : "Write the code .."} required />
                            <div className="icon"><i class="fa fa-search"></i></div>
                        </div>
                    </div>
                    <div style={{ margin: "auto", textAlign: "center", marginTop: "1rem" }} class="btn-box btns"><button onClick={(e) => filterService(e)} class="button type1"><span class="btn-txt"><a>{i18n.language == "ar" ? "إبحث" : "Search"}</a></span></button></div>
                </form>

                <div className="cards">

                    {
                        servicesSearch.length > 0 ?
                            servicesSearch.map((project, index) => {
                                return (
                                    <div className="card" key={project.id}>
                                        <Link to={`/certificates/certificate/${project.code}`}><img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${project.img}`} alt="" /></Link>
                                    </div>
                                )
                            })
                            :
                            null
                    }

                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Certificates_Page
