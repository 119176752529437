import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { fetchColor } from '../rtk/slices/color-slice'
import { fetchProjects } from '../rtk/slices/projects-slice'
import { useTranslation } from 'react-i18next'
import { fetchBookings } from '../rtk/slices/bookings-slice'

function Bookings_Page () {
    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const bookings = useSelector((state) => state.bookings.bookings);

    const { t, i18n } = useTranslation();

    const bookings_en = bookings.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img": ser_en.img,
                "title": ser_en.title_en,
                "time": ser_en.time_en,
                "location": ser_en.location_en,
                "number": ser_en.number,
            })
        }
        return ser_en;
    })


    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchBookings())

    }, [])

    return (
        <section className=' certificates bookings' id='projects'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=201226139946" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الحجوزات" : "Bookings"}</span>
                    <p>{i18n.language == "ar" ? "الحجوزات" : "Bookings"}</p>
                </h2>
                <div className="cards">
                    {bookings_en.map((card) => {
                        return (
                            <Link to={`/bookings/booking/${card.id}`} className="card" key={card.id}>
                                <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img}`} alt="" />
                                <h4 style={{ paddingTop: "1rem" }}>{card.title}</h4>
                            </Link>
                        )
                    })}
                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Bookings_Page
