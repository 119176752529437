import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { useTranslation } from 'react-i18next';
import { fetchBookings } from '../rtk/slices/bookings-slice';
import { fetchMachines } from '../rtk/slices/machines-slice';

function Machine () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const dispatch = useDispatch();
    const machines = useSelector((state) => state.machines.machines);
    const color = useSelector((state) => state.color.color);

    const machines_en = machines.map(blo_en => {
        if (i18n.language == "en") {
            return ({
                "id": blo_en.id,
                "img": blo_en.img,
                "name": blo_en.name_en,
                "used": blo_en.used_en
            })
        }
        return blo_en;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchMachines());

    }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=201226139946" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "الألات" : "Machines"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "الألات" : "Machines"}</p>
                </h2>


                {machines_en.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service">
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img}`} alt="" />
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3>{service.name}</h3>
                                        <br />
                                        <p><p style={{ background: 'var(--main-color-opacity-35)' }}>{i18n.language == "ar" ? "فيما يستخدم: " : "What is used: "}</p>
                                            {service.used.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            : null
                    )
                })}

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Machine
