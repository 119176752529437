import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchOur_Team = createAsyncThunk('our_TeamSlice/fetchOur_Team', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?our_team`)
    const data = await res.json()
    return data;
})

const our_TeamSlice = createSlice({
    initialState: {
        our_Team: [],
        loading: false,
        error: null,
    },
    name: 'our_TeamSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchOur_Team.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOur_Team.fulfilled, (state, action) => {
                state.loading = false;
                state.our_Team = action.payload;
            })
            .addCase(fetchOur_Team.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = our_TeamSlice.actions;

export default our_TeamSlice.reducer;