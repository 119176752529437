
import All from "./components/All";
import { BrowserRouter as Router, Route, Switch, Routes, BrowserRouter } from 'react-router-dom';
import Blog from "./components/Blog";
import Services_Page from "./components/Services_Page";
import Service from "./components/Service";
import Blogs_Page from "./components/Blogs_Page";
import Our_Team_Page from "./components/Our_Team_Page";
import Certificates_Page from "./components/Certificates_Page";
import Certificate from "./components/Certificate";
import Booking from "./components/Booking";
import Bookings_Page from "./components/Bookings_Page";
import Machines_Page from "./components/Machines_Page";
import Machine from "./components/Machine";
import Training from "./components/Training";


function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/services" element={<Services_Page />} />
          <Route path="/services/service/:id" element={<Service />} />
          <Route path="/ourTeam/:id" element={<Our_Team_Page />} />
          <Route path="/certificates" element={<Certificates_Page />} />
          <Route path="/certificates/certificate/:code" element={<Certificate />} />
          <Route path="/bookings" element={<Bookings_Page />} />
          <Route path="/bookings/booking/:id" element={<Booking />} />
          <Route path="/machines" element={<Machines_Page />} />
          <Route path="/machines/machine/:id" element={<Machine />} />
          <Route path="/trainings/training/:id" element={<Training />} />
          <Route path="/blogs" element={<Blogs_Page />} />
          <Route path="/blogs/blog/:id" element={<Blog />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
