import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchBookings = createAsyncThunk('bookingsSlice/fetchBookings', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?bookings`)
    const data = await res.json()
    return data;
})

const bookingsSlice = createSlice({
    initialState: {
        bookings: [],
        loading: false,
        error: null,
    },
    name: 'bookingsSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchBookings.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBookings.fulfilled, (state, action) => {
                state.loading = false;
                state.bookings = action.payload;
            })
            .addCase(fetchBookings.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = bookingsSlice.actions;

export default bookingsSlice.reducer;