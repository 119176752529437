import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchour_team } from '../rtk/slices/our_team-slice'
import { useTranslation } from 'react-i18next'
import { fetchOur_Team } from '../rtk/slices/our_team-slice'

import { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

import '../../src/ourTeam.css';
// import required modules
import { EffectCards } from 'swiper/modules';

function Our_Team () {
    const dispatch = useDispatch();
    const our_team = useSelector((state) => state.our_Team.our_Team);
    const { t, i18n } = useTranslation();

    const our_team_en = our_team.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "name": ser_en.name_en,
                "career": ser_en.career_en,
                "about": ser_en.about_en,
            })
        }
        return ser_en;
    })
    console.log(our_team);
    useEffect(() => {
        // fetch show
        dispatch(fetchOur_Team());
    }, [])

    return (
        <section className='certificates ourteam' id='ourteam'>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>{i18n.language == "ar" ? "فريق العمل" : "Our Team"}</span>
                    <p>{i18n.language == "ar" ? "فريق العمل" : "Our Team"}</p>
                </h2>
                <div className="cards">
                    <>
                        <Swiper
                            effect={'cards'}
                            grabCursor={true}
                            modules={[EffectCards]}
                            className="mySwiper"
                            initialSlide={2}
                        >
                            {our_team_en.map((card) => {
                                return (
                                    <SwiperSlide>
                                        <Link to={`/ourTeam/${card.id}`} data-aos="zoom-out-up" className="card" key={card.id}>
                                            <h2 style={{ paddingTop: "1rem", color: '#333333' }}>{card.name}</h2>
                                            <h4 style={{ paddingTop: "1rem", color: '#333333' }}>{card.career}</h4>
                                        </Link>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </>



                </div>
            </div>
        </section>
    )
}

export default Our_Team
