import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { useTranslation } from 'react-i18next'
import { fetchBookings } from '../rtk/slices/bookings-slice'
import { fetchTrainings } from '../rtk/slices/trainings-slice'
import { fetchFaq } from '../rtk/slices/faq-slice'

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={toggleAccordion}>
                {title}
                <span className="accordion-icon">{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && <div className="accordion-body">{content}</div>}
        </div>
    );
};

function FAQ () {
    const dispatch = useDispatch();
    const faq = useSelector((state) => state.faq.faq);
    const { t, i18n } = useTranslation();

    const faq_en = faq.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "title": ser_en.title_en,
                "content": ser_en.content_en,
            })
        }
        return ser_en;
    })

    useEffect(() => {
        // fetch show
        dispatch(fetchFaq());
    }, [])

    return (
        <section className='certificates faq' id='bookings' style={{ backgroundColor: 'var(--bg-color) !important' }}>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>{i18n.language == "ar" ? "الأسئلة الشائعة" : "FAQ"}</span>
                    <p>{i18n.language == "ar" ? "الأسئلة الشائعة" : "FAQ"}</p>
                </h2>
                <div className="cards">
                    {faq_en.map(el => {
                        return (
                            <AccordionItem title={el.title} content={el.content} />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default FAQ